import React from "react";
import Dates from "../../assets/Dates.jpg";
import Limo from "../../assets/Limo.png";
import "./Projects.css";
import { useNavigate } from "react-router-dom";

function Projects() {
  const navigate = useNavigate();
  function handleClick() {
    navigate("/cafe");
  }

  return (
    <div
      name="projects"
      className="w-full md:h-screen bg-gray-800 text-gray-300 p-20"
    >
      <div className="mainb max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div>
          <p className="text-4xl font-bold inline border-b-4 text-green-600 border-green-700">
            Projects
          </p>
          <p className="py-2">Check out some of my recent Projects</p>
        </div>

        {/* Countainer*/}
        <div className="mb-10 grid sm:grid-cols-2 md:grid-cols-3 gap-4">
          {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${Dates})` }}
            className="box_w shadow-lg shadow-[#040c16] 
                    group countainer rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Mover Effects */}
          </div>
          <p className="flex justify-center items-center p-5 text-2xl font-bold text-white">
            Cafè
          </p>
          <div className="flex justify-center items-center">
            <button
              className="borderround text-[#257f32] border-2 hover:bg-gray-600 hover:text-white hover:border-blue-100 mx-auto"
              onClick={handleClick}
            >
              View Project{" "}
            </button>
          </div>
        </div>

        {/* Countainer*/}
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
          {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${Limo})` }}
            className="box_w shadow-lg shadow-[#040c16] 
                    group countainer rounded-md flex justify-center items-center mx-auto content-div "
          >
            {/* Mover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="p-20 text-2xl bg-whitefont-bold text-green-800"></span>
            </div>
          </div>
          <p className="flex justify-center items-center p-5 text-2xl font-bold text-white">
            {" "}
            Limousine
          </p>
          <div className="flex justify-center items-center">
            <button
              className="borderround text-[#257f32] border-2 hover:bg-gray-600 hover:text-white hover:border-blue-100 mx-auto"
              onClick={handleClick}
            >
              View Project{" "}
            </button>
          </div>
          {/* <button className="borderrounded flex justify-center items-center text-[#257f32] border-2 hover:bg-blue-300 hover:border-blue-900 px-4 py-3 my-8 mx-auto">
          Send
        </button> */}
        </div>
      </div>
    </div>
  );
}

export default Projects;
