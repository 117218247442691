import React from "react";
import { FaFacebook, FaYoutube, FaTwitter } from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="m- footer absolute w-full h-[80px] px-4 bg-gray-900">
      <div className="text-2xl flex justify-center gap-16 text-gray-500">
        <a
          className=""
          href="https://www.facebook.com/profile.php?id=100095117465552"
        >
          <FaFacebook />
        </a>
        <a href="https://www.youtube.com/results?search_query=adil+aleidi">
          <FaYoutube />
        </a>
        <a href="https://twitter.com/AleidiAdil47502">
          <FaTwitter />
        </a>
      </div>
      <p className="text-4 flex justify-center gap-16 text-gray-600 p-2">
        Copyright © 2023 Adil Aleidi. All rights reserved.
      </p>
    </div>
  );
};

export default Footer;
