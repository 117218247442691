import React, { useState } from "react";
import { FaBars, FaTimes, FaLinkedin, FaInstagram } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";
import Logo from "../../assets/logo.png";
import { NavLink } from "react-router-dom";
import "./NavBar.css";

const NavBar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);
  return (
    <div className="nav fixed w-full h-[80px] flex justify-between items-center px-4 bg-gray-900 text-green-600">
      <div className="app_navbar-logo">
        <a href="https://adilaleidi.com/">
          <img src={Logo} alt="logo" />
        </a>
      </div>
      {/* menu */}
      <div>
        <nav className="app__navbar">
          <ul className="hidden md:flex">
            <li className="app__navbar-links navbar">
              <NavLink
                exact
                activeClassName="active"
                to="/"
                className="p__opensans nav-link nav-link-ltr"
              >
                Home
              </NavLink>
            </li>
            <li className="app__navbar-links navbar">
              <NavLink
                activeClassName="active"
                to="about"
                className="p__opensans nav-link nav-link-ltr"
              >
                About
              </NavLink>
            </li>
            <li className="app__navbar-links navbar">
              <NavLink
                activeClassName="active"
                to="projects"
                className="p__opensans nav-link nav-link-ltr"
              >
                Projects
              </NavLink>
            </li>
            <li className="app__navbar-links navbar">
              <NavLink
                activeClassName="active"
                to="/contact"
                className="p__opensans nav-link nav-link-ltr"
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
      {/* humburger */}
      <div onClick={handleClick} className="md:hidden z-2 cursor-pointer">
        {!nav ? <FaBars /> : <FaTimes />}
      </div>
      {/* mobile menu */}
      <ul
        className={
          !nav
            ? "hidden"
            : "absolute top-0 left-0 w-full h-screen bg-slate-800 flex flex-col justify-center items-center"
        }
      >
        <li className="app__navbar-links py-6 text-4xl">
          <a href="/">Home</a>
        </li>
        <li className="app__navbar-links py-6 text-4xl">
          <a href="about">About</a>
        </li>
        <li className="app__navbar-links py-6 text-4xl">
          <a href="projects">Projects</a>
        </li>
        <li className="app__navbar-links py-6 text-4xl">
          <a href="contact">Contact</a>
        </li>
      </ul>

      {/* Social icons */}
      <div className="hidden lg:flex fixed flex-col top-[35%] left-0">
        <ul>
          <li className="socialicons p-4 w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-gray-500">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="https://www.linkedin.com/in/adil-aleidi-03284567/"
            >
              linkedin <FaLinkedin size={30} />
            </a>
          </li>
          <li className="socialicons p-4 w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-gray-700">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="https://www.instagram.com/adilaleidi/"
            >
              Instagram <FaInstagram size={30} />
            </a>
          </li>
          <li className="socialicons p-4 w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-gray-800">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="mailto:adilaleidi@gmail.com"
            >
              Email <HiOutlineMail size={30} />
            </a>
          </li>
          {/* I have to come back and finish*/}
          <li className="socialicons p-4 btnDiv w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-gray-950">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              id="downloadBtn"
              value="download"
              href="http://adilaleidi.com/static/media/resume.pdf"
            >
              Resume <BsFillPersonLinesFill size={30} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavBar;
