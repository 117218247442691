import React from "react";
// import ReactDOM from "react-dom";
import { Routes, Route, useLocation } from "react-router-dom";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Home from "./components/home/Home";
import Projects from "./components/works/Projects";
// import Skills from "./components/Skills";
import NavBar from "./components/navbar/NavBar";
import Cafe from "./components/works/cafe/Cafe";
import Footer from "./components/footer/Footer";

// import Education from "./components/education/Education";

function App() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/cafe" element={<Cafe />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
