import * as React from "react";
import "./GoldenCafe.css";
// import Dates from "../../../assets/the_golden_dates/the-golden-dates-cafe.png";
// import Empathize from "../../assets/the_golden_dates/Empathize.png";
// import Research from "../../assets/the_golden_dates/research.png";

// import "./Projects.css";

function Cafe() {
  return <div></div>;
}

export default Cafe;
