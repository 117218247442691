import React from "react";
// import { HiArrowNarrowRight } from "react-icons/hi";
import "./Home.css";
import Adil from "../../assets/Adil_b&WAAs.png";
// import { Link } from "react-scroll";

const Home = () => {
  return (
    <div name="home" id="intro">
      <div className="introContent">
        <span className="hello">Hello!</span>
        <span className="interText">
          I'm<span className="introrName"> Adil,</span>
          <br />
          <b className="interfre">A freelance UI</b>
          <b className="lin"> / </b>
          <b className="interux">UX designer.</b>
        </span>
        <p className="introPara">
          I help businesses to design intuitive digital products and websites.
        </p>
        {/* <Link>
          <button className="btn text-white border-2 hover:bg-gray-600 hover:text-black hover:border-blue-100">
            Hier Me
          </button>
        </Link> */}
      </div>
      <img className="photo mx-auto" src={Adil} alt="Adil" />

      {/* Container */}
      {/* <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
        <p className="font-bold inlin text-gray-500 text-4xl sm:text-3xl">
          Hi, I'm
        </p>
        <p className="font-bold inlin text-7xl name_adil text-green-700 mb-4">
          ADIL
        </p>
        <p className="text-2xl sm:text-7xl font-bold text-gray-300 free_lance mt-4">
          A freelance UI/UX designer.
        </p>
        <p className="help_b text-3xl sm:text-5xl font-bold text-gray-300">
          I help businesses to design intuitive digital products and websites.
        </p> */}
      {/* <div>
          <button
            href="./projects"
            className="text-green-100 group border-2 px-6 py-5 my-2 flex itmes-center hover:bg-blue-900 hover:border-gray-300"
          >
            View Work
            <span className="group-hover:rotate-90 duration-300">
              <HiArrowNarrowRight className="ml-3" />
            </span>
          </button>
        </div> */}
      {/* </div> */}
    </div>
  );
};

export default Home;
