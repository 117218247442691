import React from "react";
import "./About.css";

const About = () => {
  return (
    <div name="about" className="w-full h-screen bg-gray-800 text-gray-200">
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-12">
          <div className="pb-5 sm-text-right pl-4">
            <p className="text-4xl font-bold inline border-b-4 text-green-700 border-green-600">
              About
            </p>
          </div>
          <div></div>
        </div>
        <div className="max-w-[1300px] w-full grid sm:grid-cols-2 gap-8 px-4">
          <div className="sm-text-right text-4xl font-bold">
            <p>Hi, I'm Adil, nice to meet you. Please take a look around.</p>
          </div>
          <div>
            <h3 className="text-2xl letter-spacing: 0.2em">
              I would like to express my appreciation to everyone who has
              examined my portfolio. I have more than fiften years of experience
              as an educated designer. I have experience working as a freelancer
              and for companies. I always start any project by collecting
              information and sketching ideas, then transfer them to digital
              using Figma, Adobe Suite, or other software. Challenges and
              creativity are something I love. Please check my resume for more
              info.
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
